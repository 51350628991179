import YAML from 'yaml';
import React, { Component } from 'react';
import { Link } from "gatsby";
import getIcon from './icons';
import './DownloadApplication.scss';

const BUCKET_BASE_URL = "https://storage.googleapis.com/downloads.sharekey.com";
const BUCKET_DOWLOAD_URL = "https://downloads.sharekey.com";
const UPDATE_CHANNEL = "latest";
const PLATFORMS = ["mac", "win"];
const CHANNEL_FILE_URL = {
	win: `${BUCKET_BASE_URL}/${UPDATE_CHANNEL}.yml`,
	mac: `${BUCKET_BASE_URL}/${UPDATE_CHANNEL}-mac.yml`
};

export default class DownloadApplication extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeItem: null,
			winDownloadURL: null,
			macDownloadURL: null
		};

		this.onMouseOver = this.onMouseOver.bind(this);
		this.onMouseLeave = this.onMouseLeave.bind(this);
	}

	componentDidMount() {
		for(const platform of PLATFORMS)
			DownloadApplication.getDownloadLink(platform)
				.then(url => {
					this.setState({[`${platform}DownloadURL`]: url})
				})
	}

	static async getDownloadLink(platform) {
		const response = await fetch(CHANNEL_FILE_URL[platform]);
		const yaml = await response.text();
		const object = await YAML.parse(yaml);
		const {path, files} = await object;
		const fileName = (platform !== "mac")? files[0].url : files.filter(file => (file.url !== path))[0].url;
		const url = `${BUCKET_DOWLOAD_URL}/${fileName}`;
		return url;
	}

	onMouseOver({ target }) {
		if (true === target.classList.contains("windows"))
			return this.setState({ activeItem: "windows" });

		if (true === target.classList.contains("macos"))
			return this.setState({ activeItem: "macos" });

		if (true === target.classList.contains("linux"))
			return this.setState({ activeItem: "linux" });
	}

	onMouseLeave() {
		this.setState({ activeItem: null });
	}

	render() {
		const { activeItem, winDownloadURL, macDownloadURL } = this.state;

		return(
			<div className="scroll">
				<section className="downloads-container g-container">
					<div className="capture">Desktop Download</div>
					<div className="description" dangerouslySetInnerHTML={{ __html:  this.props.data.downloadsDescription.edges[1].node.content.childContentfulRichText.html}} />
					<ul className="downloads-list">
						<li className="downloads-item">
							<a href={winDownloadURL} onClick={() => window.plausible('Click Download Win App', {props: {page: window.location.pathname}})} className="link">
								<div onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave} className="download-block windows">
									<div className="os-icon">
										{ activeItem === 'windows' ? getIcon('windows white') : getIcon('windows grey')}
									</div>
									<div className="item-capture">Desktop App for Windows</div>
								</div>
							</a>
							{/*<div className="portable-version-link">Portable version for Windows</div>*/}
						</li>
						<li className="downloads-item">
							<a href={macDownloadURL} onClick={() => window.plausible('Click Download Mac App', {props: {page: window.location.pathname}})} className="link">
								<div onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave} className="download-block macos">
									<div className="os-icon">
										{ activeItem === 'macos' ? getIcon('macos white') : getIcon('macos grey')}
									</div>
									<div className="item-capture">Desktop App for macOS</div>
								</div>
							</a>
							{/*<div className="portable-version-link">Portable version for macOS</div>*/}
						</li>
						<li className="downloads-item">
							<Link to='/support' onClick={() => window.plausible('Contact Us For Linux Version', {props: {page: window.location.pathname}})} state={{ prevPath: this.props.location.pathname }}>
								<div onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave} className="download-block linux">
									<div className="os-icon">
										{ activeItem === 'linux' ? getIcon('linux white') : getIcon('linux grey')}
									</div>
									<div className="item-capture">Contact Us For Linux Version</div>
								</div>
							</Link>

						</li>
					</ul>
				</section>
			</div>
		);
	}
}
